import React from 'react'
import { Article, Feature, Feature_bg_lg } from '../../components'
import './services.css'
import {AiOutlineCloudServer} from 'react-icons/ai'
import {MdOutlineComputer, MdOutlineCorporateFare} from 'react-icons/md'
import {FaIndustry} from 'react-icons/fa'
import {ImUserCheck} from 'react-icons/im'
import {SiProbot, SiAtom, SiChainlink} from 'react-icons/si'
import { useTranslation } from 'react-i18next'

const featureData = [
    {
        icon: <FaIndustry size={60}/>,
        title: 'services.item1Title',
        // text: 'services.item1Title'
    },
    {
        icon: <MdOutlineComputer size={60}/>,
        title: 'services.item2Title',
        // text: 'services.item2Title'
    },
    {
        icon: <SiAtom size={60}/>,
        title: 'services.item3Title',
        // text: 'services.item3Title'
    },
    {
        icon: <AiOutlineCloudServer size={60}/>,
        title: 'services.item4Title',
        // text: 'services.item4Title'
    },
    {
        icon: <SiProbot size={60}/>,
        title: 'services.item5Title',
        // text: 'services.item5Title'
    },
    {
        icon: <MdOutlineCorporateFare size={60}/>,
        title: 'services.item6Title',
        // text: 'services.item6Title'
    },  
    {
        icon: <SiChainlink size={60}/>,
        title: 'services.item7Title',
        // text: 'services.item7Title'
    }    
]

function Services() {
    const { t } = useTranslation()
  return (
    <div className='omnix__services section__padding' id='services'>
        <div className='omnix__services-heading'>
            <div />
        <h1 className='gradient__text'>{t('services.title1')}</h1>
        <p>{t('services.title2')}</p>
        </div>    
        <div className='omnix__services-container'>
            {featureData.map((item, index) => (
                <Article icon={item.icon} title={t(item.title)} text={t(item.text)} key={item.title + index}/>
            ))}
        </div>    
    </div>
  )
}

export default Services