import React, { Suspense } from 'react';
import './App.css';
import {Brand, Navbar, } from './components'
import {Header, About, Services, Flow, Contact, Footer} from './containers'
import Configuration from './containers/configuration';
import i18next from 'i18next';
import { Language } from './constants/languages'

function App() {

  const handleOnChangeLanguage = (lng: Language) => i18next.changeLanguage(lng);
  
  return (       
    <>    
    <Suspense fallback='loading'>
    <div className="App">      
      <Navbar />
      <div >     
     <Header />
     </div>
     <Brand />
     <About />
     <Services />     
     <Flow />
     <Contact />
     <Footer />
     <Configuration 
      onChangeLanguage={handleOnChangeLanguage}
      />
     
    </div>
    </Suspense>
    </>
  )
}

export default App
