import React from 'react'
import { MdEmail, MdLocalPhone } from 'react-icons/md'
import ContactForm from '../../components/contactForm/ContactForm'
import ContactInfoItem from '../../components/contactInfoItem/ContactInfoItem'
import './contact.css'
import { useTranslation } from 'react-i18next'

const Contact = () => {
    const { t } = useTranslation()

  return (
    <div className='omnix__services section__padding' id='contact'>
        <div className='omnix__services-heading'>
            <div />
                <h1 className='gradient__text'>{t('contact.title1')}</h1>
                <p>{t('contact.title2')}</p>            
        </div>  

        <div className='omnix__contact-wrapper' >
            {/* <div className='left'>
                <ContactInfoItem icon={<MdLocalPhone />}
                text="+1 (321) 732-9798" />
                <ContactInfoItem icon={<MdEmail />}
                text="p2marcano@gmail.com" />
                <ContactInfoItem 
                text='Ontario, New York'/>
            </div> */}
            <div className='right'>
                <ContactForm />
            </div>
        </div>  
     </div>
        
  )
}

export default Contact