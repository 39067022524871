import React from 'react'
import './header.css'
import logo from '../../assets/marketing1.png'
import { useTranslation } from 'react-i18next'

function Header() {
    const { t } = useTranslation()

  return (
    <div className='omnix__header section__padding bg__app' id='home'>
        <div className='omnix__header-content'>
            <h1 className='gradient__text'>{t('hero.title1')}</h1>
    <p>{t('hero.subtitle')}</p>
        <div className='omnix__header-content__input'>
            <button type='button' >{t('hero.btn_Title')}</button>
        </div>        
        </div>
        <div className='omnix__header-image slide-left'>
            <img src={logo} alt="logo" />
        </div>
    </div>
  )
}

export default Header