import React from 'react'
import { useTranslation } from 'react-i18next'
import './footer.css'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <div className='omnix__footer'>
        <p>copyright &copy; {t('footer.footer_copy')}</p>
    </div>
  )
}

export default Footer