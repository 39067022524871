import React, {useState} from 'react'
import {RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import './navbar.css'
import logo from '../../assets/omnix.png'
import { useTranslation } from 'react-i18next'



function Navbar() {
    const [toggleMenu, setToggleMenu] = useState(false);
    const { t } = useTranslation()

    const Menu = () => (
        <>
        <p><a href='#home'>{t('nav.home')}</a></p>
        <p><a href='#about'>{t('nav.about')}</a></p>
        <p><a href='#services'>{t('nav.services')}</a></p>
        <p><a href="#workFlow">{t('nav.workFlow')}</a></p>
        <p><a href='#contact'>{t('nav.contact')}</a></p>
        </>
    )
  return (
    <div className='omnix__navbar'>
        <div className='omnix__navbar-links'>
            <div className='omnix__navbar-links_logo'>
                <img src={logo} alt="logo" />
            </div>
            <div className='omnix__navbar-links_container'>
                <Menu />
            </div>
        </div>
        <div className='omnix__navbar-menu'>
    {toggleMenu 
        ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
        : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
    }

    {toggleMenu && (
        <div className='omnix__navbar-menu_container scale-up-center'>
            <div className='omnix__navbar-menu_container-links'>
                <Menu />
            </div>
        </div>
    )}
        </div>
    </div>
  )
}

export default Navbar