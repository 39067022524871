import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { Language } from "./constants/languages";


const Languages = [Language.EN, Language.ES]



i18next.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    fallbackNS: 'es',
    debug: false,
    whitelist: Languages
});

