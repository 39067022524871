import React, { useState } from 'react';
import "primereact/resources/themes/mdc-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
 
import { OrganizationChart } from 'primereact/organizationchart';
import './treeOrg.css'



const TreeOrg = () => {
    const [selection, setSelection] = useState([]);
    const data1 = [{
        label: 'CEO',
        type: 'person',
        className: 'p-person',
        expanded: true,
        data: { name: 'Pedro Marcano', 'avatar': 'walter.jpg' },
        children: [
            {
                label: 'Full Stact',
                type: 'person',
                className: 'p-person',
                expanded: true,
                data: { name: 'Eiker ', 'avatar': 'saul.jpg' },
                children: [{
                    label: 'Web',
                    className: 'department-cfo'
                },
                {
                    label: 'Backend',
                    className: 'department-cfo'
                }],
            },
            {
                label: 'Full Stact',
                type: 'person',
                className: 'p-person',
                expanded: true,
                data: { name: 'Ricardo Acurero', 'avatar': 'mike.jpg' },
                children: [{
                    label: 'Android',
                    className: 'department-coo'
                },
                {
                    label: 'Backend',
                    className: 'department-coo'
                }]
            },
            {
                label: 'iOS Developer',
                type: 'person',
                className: 'p-person',
                expanded: true,
                data: { name: 'Ricardo Sanchez', 'avatar': 'jesse.jpg' },
                children:  [{
                        label: 'Analysis',
                        className: 'department-cto'
                    },
                    {
                        label: 'Front End',
                        className: 'department-cto'
                    },
                    {
                        label: 'iOS Developer',
                        type: 'person',
                        className: 'p-person',
                        expanded: true,
                        data: { name: 'Jose Caraballo', 'avatar': 'jesse.jpg' },
                        children:  [{
                                label: 'Design',
                                className: 'department-cto'
                            },
                            {
                                label: 'iOS Native',
                                className: 'department-cto'
                            }]                
                    },
                    {
                        label: 'iOS Developer',
                        type: 'person',
                        className: 'p-person',
                        expanded: true,
                        data: { name: 'Alejandro Simompietri', 'avatar': 'jesse.jpg' },
                        children:  [{
                                label: 'Design',
                                className: 'department-cto'
                            },
                            {
                                label: 'iOS Native',
                                className: 'department-cto'
                            },
                            {
                                label: 'Scrum Master',
                                className: 'department-cto'
                            }]                
                    }]                
            },
            
        ]
    }];

    const nodeTemplate = (node) => {
        if (node.type === "person") {
            return (
                <div>
                    <div className="node-header">{node.label}</div>
                    <div className="node-content">
                        <img alt={node.data.avatar} src={`images/organization/${node.data.avatar}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} style={{ width: '32px' }} />
                        <div>{node.data.name}</div>
                    </div>
                </div>
            );
        }

        return node.label;
    }
    return (
        <div className="organizationchart-demo section__padding">
            <div className='omnix__team-heading'>
            <div />
                <h1>Team</h1>
                </div>
            <div className="card">
            
                <OrganizationChart value={data1} nodeTemplate={nodeTemplate} selection={selection} selectionMode="multiple"
                    onSelectionChange={event => setSelection(event.data)} className="company"></OrganizationChart>

                
            </div>
        </div>
    )
}

export default TreeOrg