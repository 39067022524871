import React from 'react'
import './brand.css'
import { zara, inditex, intergrow, regius, wjcNY, sodexho, rs, sinNotaria } from './import'
import { useTranslation } from 'react-i18next'

const brand = [
    {
        img: zara,
        text: 'zara'
    },
    {
        img: inditex,
        text: 'Inditex'
    },
    {
        img: intergrow,
        text: 'Intergrow'
    },    
    {
        img: sinNotaria,
        text: 'SinNotaria'
    },    
    {
        img: wjcNY,
        text: 'Worker Justice Center of New York'
    },
    {
        img: sodexho,
        text: 'Sodexho'
    },
    {
        img: rs,
        text: 'rs'
    },
    {
        img: regius,
        text: 'Regius'
    }
]

const Clients = ({img, text}) => {
    return (
        <div>
         <img src={img} alt={text}/>           
         </div>
    )
}

function Brand() {
    const { t } = useTranslation()

  return (
    <div className='omnix__brand section__padding'>
      <div className='omnix__services-heading'>
            <div />
        <h1 className='gradient__text'>{t('brand.title1')}</h1>
       </div>
      
      <div className='omnix__brand-container'>   
      {brand.map((item, index) => (     
        <Clients img={item.img} text={item.text} key={index}/>                    
            ))}
        {/* <div>
            <img src={intergrow} alt='intergrow'/>           
        </div>
        <div>
            <img src={zara} alt='Zara'/>
        </div>
        <div>
            <img src={inditex} alt='Inditex'/>
        </div>
        <div>
            <img src={regius} alt='Regius'/>           
        </div>
        <div>
            <img src={wjcNY} alt='Worker Justice Center of New York'/>
        </div>
        <div>
            <img src={sodexho} alt='sodexho'/>           
        </div>
        <div>
            <img src={rs} alt='rs Agency'/>
        </div> */}
        </div>
    </div>
  )
}

export default Brand