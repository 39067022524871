import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {createRoot} from "react-dom/client";
import App from './App';
import './index.css';
import "./i18n.js"

const rootElement = document.getElementById('root');

// 👇️ use type assertion
const root = createRoot(rootElement as Element);
root.render(
  <BrowserRouter>
  <Routes>
          <Route index element={<App/>}/>
  </Routes>
  </BrowserRouter>)
