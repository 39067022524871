import React from 'react'
import './article.css'

const Article = ({icon ,title, text}) =>  {
  return (
    <div className='omnix__article-container'>
        <div>
          <samp>
            {icon} 
            </samp>          
        </div>
        <div className='omnix__article-title'>            
            <h3>{title}</h3>            
        </div>    
        <div className='omnix__article-text'>
            <p>{text}</p>
        </div> 
    </div>
  )
}

export default Article