import React from 'react'
import { Language } from '../../constants/languages'
import { SplitButton } from 'primereact/splitbutton';
import { useTranslation } from 'react-i18next'
import './fas.css'

interface IConfigurationProps {
    onChangeLanguage: (language: Language) => void;
}

const Configuration = ({
  onChangeLanguage
}: IConfigurationProps) => {

const items = [
  {
      label: 'En',
      command: () => onChangeLanguage(Language.EN)   
  },
  {
      label: 'Es',
      command: () => onChangeLanguage(Language.ES)     
  }
]

const { t } = useTranslation()
  return (
    <div className='fab_container' >     
    <SplitButton label={t('languaje.title')} model={items} className="p-button-rounded p-button-info  mr-2 mb-2"/>
    </div>
  );
};
export default Configuration;