import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import './contactForm.css'
import ReCAPTCHA from 'react-google-recaptcha'
import emailjs from "emailjs-com";
// import 'dotenv/config'
// import express from 'express'

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [captchaValidate, setCaptcha] = useState(null);
    const { t } = useTranslation()
    const captchaRef = useRef(null)
    // require('dotenv').config()

    const onChange = () => {
        if (captchaRef.current.getValue()) {
            setCaptcha(true)
        }
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        if (captchaRef.current.getValue()) {
            setCaptcha(true)
            sendEmail(e)
        } else {
            setCaptcha(false)
        }        
    }

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_ylb0y6e', 'template_cotc6pp', e.currentTarget, 'nGxzecKyXKLAUL2Z0')
          
        e.target.reset()
        e.currentTarget.reset()
        setName('')
        setEmail('')
        setMessage('')
        
        alert(t('contact.success'))
        captchaRef.current?.reset();
        }

  return (
    <div>
        <form className='form-Container' onSubmit={handleSubmit.bind(this)}>
            <div className='form-group'>
                <label htmlFor="name">
                {t('contact.name')}  <input type="text" id='name' name='name' value={name} required onChange={e => setName(e.target.value)} />
                </label>
            </div>
            <div className='form-group'>
                <label htmlFor="email">
                {t('contact.email')}  <input type="email" id='email' name='email' value={email} required onChange={e => setEmail(e.target.value)} />
                </label>
            </div>
            <div className='form-group'>
                <label htmlFor="message">
                {t('contact.message')}  <textarea type="text" id='message' name='message' value={message} required onChange={e => setMessage(e.target.value)} />
                </label>
            </div>
            <div className='omnix__form-content__input'>
            <ReCAPTCHA
            ref={captchaRef}
            sitekey= {process.env.REACT_APP_SITE_KEY}
            onChange={onChange}
            theme="dark"
            type='image'
             />
             {captchaValidate === false && <div className='error-captcha' >
             {t('contact.recaptcha')}
             </div>}
            <button type='submit' >{t('contact.buttonText')}</button>
            
        </div> 
        </form>
        
    </div>
  )
}

export default ContactForm