import React from 'react'
import './feature.css'
import { useTranslation } from 'react-i18next'

const Feature = ({title, text}) => {
  const { t } = useTranslation()

  return (
    <div className='omnix__features-container__feature'>
        <div className='omnix__features-container__feature-title'>
            <div/>
            <h1 className='gradient__text'>{t(title)}</h1>            
        </div>    
        <div className='omnix__features-container__feature-text'>
            <p className='card-p-text'>{t(text)}</p>
        </div>    
    </div>
  )
}

export default Feature