import React from 'react'
import { Feature } from '../../components'
import './about.css'
import { useTranslation } from 'react-i18next'

function About() {
  const { t } = useTranslation()

  return (
    <div className='omnix__about section__margin' id='about'>
        <div className='omnix__about-feature'>
            <Feature title={t("about.topTitle")}
            text={t("about.description")}/>
        </div>
        <div className='omnix__about-heading'>
            <h1 className='gradient__text'>{t("about.subTitle")}</h1>
            <p>{t("about.subtitleDescription")}</p>
        </div>

        {/* <div className='omnix__about-container'>
            <Feature title="WEB" text="Aplicación de talento.
            Registro de Ausencias.
            Planificación laboral.
            Control de horas.
            Integración con sistemas externos.
            Control de Beneficios."/>
            <Feature title="iOS" text="Aplicación de talento.
            Registro de Ausencias.
            Planificación laboral.
            Control de horas.
            Integración con sistemas externos.
            Control de Beneficios."/>
            <Feature title="Android" text="Aplicación de talento.
            Registro de Ausencias.
            Planificación laboral.
            Control de horas.
            Integración con sistemas externos.
            Control de Beneficios."/>
        </div> */}
    </div>
  )
}

export default About