
import React, { useRef } from 'react';
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next'
import './flow.css';

const Flow = () => {
    const { t } = useTranslation()

    const events1 = [
        { status: 'workFlow.item1title',  icon: 'pi pi-book', color: '#9C27B0',  textContent: 'workFlow.item1Description'},
        { status: 'workFlow.item2title', icon: 'pi pi-cog', color: '#673AB7', textContent: 'workFlow.item1Description' },
        { status: 'workFlow.item3title', icon: 'pi pi-chart-line', color: '#FF9800', textContent: 'workFlow.item1Description'},
        { status: 'workFlow.item4title', icon: 'pi pi-check', color: '#49be25', textContent: 'workFlow.item1Description' }
    ];

    const events2 = [
        '2020', '2021', '2022', '2023'
    ];
    

    const customizedMarker = (item) => {
        return (
            <span className="custom-marker shadow-1" style={{ backgroundColor: item.color }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const customizedContent = (item) => {
        return (
            <Card title={t(item.status)} subTitle={item.date} className="card-bg" >
                { item.image && <img src={`images/product/${item.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.name} width={200} className="shadow-1" />}
                <p className='card-p-text'>{t(item.textContent)}</p>
               
            </Card>
        );
    };

    return (
        <div className="timeline-demo section__padding" id='workFlow'>        
             <div className="card">
             <div className='omnix__flow-heading'>
                <div />
                <h1 className='gradient__text'>{t('workFlow.title1')}</h1>
                </div>
                <Timeline value={events1} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
            </div>           
        </div>
    );
}
                
export default Flow